import { navConfig } from "./config";
import { Link } from "react-router-dom";

import cn from "classnames";
import styles from "./Footer.module.scss";

const Footer = ({ className }) => {
  return (
    <footer className={cn(styles["footer"], className)}>
      <div className={cn("wrapper", styles["footer__wrapper"])}>
        <div className={styles["footer__content"]}>
          <div className={styles["footer__content-left"]}>
            <Link to="/">
              <img
                className={styles["footer__logo"]}
                src={"/images/logo.svg"}
                width="104"
                height="32"
                alt="KikObear"
              />
            </Link>
            <span className={styles["footer__content-address"]}>
              IPL GROUP PTY LTD, Suite 1, 215 Albany Street N, Gosford NSW 2250, Australia
            </span>
          </div>
          <div className={styles["footer__content-right"]}>
            <p>
              To contact us, please email{" "}
              <a href="mailto:support@kikobear.net">
                support@kikobear.net
              </a>
            </p>
            <NavFooter />
          </div>
        </div>

        <div className={styles["footer__content-bottom"]}>
          <div>
            <h4 className={styles["title"]}>Secure online payment:</h4>
            <div className={styles["footer__payment-list"]}>
              <figure>
                <img
                  width="48"
                  height="32"
                  src={"/images/common/pay-icon/visa.svg"}
                  alt="visa"
                />
              </figure>
              <figure>
                <img
                  width="48"
                  height="32"
                  src={"/images/common/pay-icon/mastercard.svg"}
                  alt="mastercard"
                />
              </figure>
              <figure>
                <img
                  width="48"
                  height="32"
                  src={"/images/common/pay-icon/american.svg"}
                  alt="american express"
                />
              </figure>
              <figure>
                <img
                  width="48"
                  height="32"
                  src={"/images/common/pay-icon/discover.svg"}
                  alt="discover"
                />
              </figure>
            </div>
          </div>

          <div>
            <h4 className={styles["title"]}>Approved by:</h4>
            <div className={styles["footer__payment-list"]}>
              <figure>
                <img
                  width="48"
                  height="32"
                  src={"/images/common/pay-icon/jcb.svg"}
                  alt="jcb"
                />
              </figure>
              <figure>
                <img
                  width="48"
                  height="32"
                  src={"/images/common/pay-icon/wire.svg"}
                  alt="wire"
                />
              </figure>
              <figure>
                <img
                  width="48"
                  height="32"
                  src={"/images/common/pay-icon/debit.svg"}
                  alt="direct debit"
                />
              </figure>
              <figure>
                <img
                  width="101"
                  height="32"
                  src={"/images/common/pay-icon/credit.svg"}
                  alt="CreditCardSafe"
                />
              </figure>
              <figure>
                <img
                  width="66"
                  height="32"
                  src={"/images/common/pay-icon/pci.svg"}
                  alt="pci"
                />
              </figure>
            </div>
          </div>
          <div>
            <p className={styles["footer__copy"]}>
              © {new Date().getFullYear()} kikobear.net
              <br />
              All trademarks are the property of their respective owners.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

const NavFooter = () => {
  return (
    <div className={styles["footer__nav"]}>
      <nav>
        <ul className={styles["footer__list"]}>
          {navConfig.map(({ href, text }) => {
            return (
              <li key={href} className={styles["footer__list-item"]}>
                <Link
                  className={styles["footer__list-link"]}
                  to={href}
                  aria-label={text}
                >
                  {text}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Footer;
